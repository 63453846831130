body {
  background: #F6F7FC;
  margin: 0;
  font-family: Tahoma;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: #f1f1f1 #c1c1c1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

  /* Ancho y color del scrollbar */
  ::-webkit-scrollbar {
    width: 7px;
    height: 10px;
    background-color: #f5f5f5;
  }
  
  /* Estilo del scrollbar */
  ::-webkit-scrollbar-thumb {
    background-color: #a5a5a5;
    border-radius: 10px;
  }
  
  /* Estilo del scrollbar al pasar el mouse */
  ::-webkit-scrollbar-thumb:hover {
    background-color: gray;
  }