:root {
    --swiper-navigation-size: 18px;
}

.carousel .swiper {
    height: 60vh;
}

@media(min-width:1000px){
    .carousel .swiper {
        height: 66vh;
    }
}

.carousel .swiper-button-prev{
    user-select: none;
    position: absolute;
    left: 5%;
    right: auto;
    top: 50%;
    opacity: 0.4;
    padding: 5px;
    background: #015FC9;
    color: white;
    font-size: 5px;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    border: none;
    scale: none;
}

.carousel .swiper-button-next{
    user-select: none;
    position: absolute;
    right: 5%;
    left: auto;
    top: 50%;
    opacity: 0.4;
    padding: 5px;
    background: #015FC9;
    color: white;
    font-size: 5px;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    border: none;
    scale: none;
}

.carousel .swiper-button-next:hover{
    opacity: 100;
    filter: none;
}

.carousel .swiper-button-prev:hover{
    opacity: 100;
    filter: none;
}


