@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities{
    .shadow-solida{
        box-shadow: 15px 15px 0 white;
    }
    .shadow-solida-black{
        box-shadow: 15px 15px 0 black;
    }
}